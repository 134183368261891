import { MessageModel } from '@chatscope/chat-ui-kit-react';
export function convertToChatbotMessages(messages: MessageModel[]): SFChatbotMessage[] {
  const chatbotMessages: SFChatbotMessage[] = [];
  for (const message of messages) {
    const newMessage: SFChatbotMessage = {
      author: message.direction === 'incoming' ? 'bot' : 'user',
      content: message.message || ''
    };
    chatbotMessages.push(newMessage);
  }
  return chatbotMessages;
}

export function convertToMessage(prediction: string): MessageModel {
  return {
    message: prediction,
    direction: 'incoming',
    position: 'single',
    //   position: 'normal',
    sender: 'bot'
  };
}
