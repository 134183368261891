import { Button, InputLabel, TextField } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from './Services/Chatbot';
import Spinner from 'react-bootstrap/Spinner';

export default function Home() {
  const navigate = useNavigate();
  const token = localStorage.getItem('sfChatbotToken');
  const [textInput, setTextInput] = useState(token || '');
  const [authFailed, setAuthFailed] = useState(false);
  const [signinIn, setSigninIn] = useState(false);

  async function handleSend(key: string) {
    try {
      setSigninIn(true);
      await login(key);
      setSigninIn(false);
      localStorage.setItem('sfChatbotToken', key);
      navigate('/chat');
    } catch (e) {
      if (e instanceof Error && e.message === 'AUTH_FAILED') {
        setAuthFailed(true);
        setSigninIn(false);
        return;
      }
      throw e;
    }
  }
  const handleInput = (event: any) => {
    setTextInput(event.target.value);
  };

  return (
    <div style={{ position: 'relative' }} className="text-center" id="signin">
      <InputLabel>
        <strong>Insert Auth Token</strong>
      </InputLabel>
      <TextField
        value={textInput}
        onChange={handleInput}
        error={authFailed}
        helperText={authFailed ? 'Invalid token. Please make sure there are no whitespaces' : ''}
      ></TextField>
      <div>
        <Button
          variant="contained"
          className="signin-button"
          onClick={function () {
            handleSend(textInput);
          }}
        >
          Sign in
        </Button>
        <div className="clear"></div>
        <Spinner hidden={!signinIn} animation="border" role="status" id="signin-spinner">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </div>
  );
}
