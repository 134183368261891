import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

import {
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  MessageModel,
  TypingIndicator
} from '@chatscope/chat-ui-kit-react';
import { Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize, withStyles } from '@mui/material';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { sendFeedback } from './Services/Chatbot';
import { convertToMessage } from './utils';
import escape from 'escape-html';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { WS_API_URL } from './config';
import { WSConnectionStatus } from './enums';
import _ from 'lodash';

export default function Chat() {
  const websocketConnectionUrl = WS_API_URL + '/api/websocket?accessToken=' + localStorage.getItem('sfChatbotToken')
  const [lastMessage, setLastMessage] = useState<MessageEvent>();
  const [readyState, setReadyState] = useState<ReadyState>(-1);
  const [socket, setSocket] = useState<WebSocket>();
  const navigate = useNavigate();

  const connectToWebSocket = async function () {
    const ws: WebSocket = new WebSocket(websocketConnectionUrl)
    ws.onopen = function() {
      // subscribe to some channels
      setReadyState(ws.readyState)
      ws.send(JSON.stringify({ type: 'init', payload: { conversationId, messages } }));
    };

    ws.onmessage = function(e) {
      setLastMessage(e);
    };

    ws.onclose = function(e) {
      setReadyState(ws.readyState)
    };

    ws.onerror = function(err:any) {
      console.error('Socket encountered error: ', err.message, 'Closing socket');
      ws.close();
    };    
    setSocket(ws);
    return new Promise<WebSocket>(function(resolve, reject) {
      const start = Date.now();
      
      const interval = setInterval(function() {
        const timer = Date.now() - start
        if (ws.readyState !== 0) {
          if (ws.readyState === 1) {
            clearInterval(interval);
            resolve(ws);
          } else if (ws.readyState === 3) {
            clearInterval(interval);
            reject(new Error('Websocket connection failed'));
          }
        } else if (timer > 30000){
            clearInterval(interval);
          reject(new Error('Websocket connection could not be established in time'))
        }
      }, 100)
    })
  }

  const [messages, setMessage]: [messages: MessageModel[], setMessage: any] = useState([]);
  const [isChatbotTyping, setIsChatbotTyping] = useState(false);
  const [conversationId] = useState(nanoid());
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [sentiment, setSentiment] = useState('like');
  const [feedbackComment, setFeedbackComment] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('sfChatbotToken');
    if (!token) navigate('/');
  });

  useEffect(() => {
    if (!_.isNil(lastMessage)) {
      const data = JSON.parse(lastMessage.data);
      if (data.type === 'chunk') {
        const newMessages: any = [...messages];
        const latestMessage = newMessages[newMessages.length - 1];
        if (latestMessage.direction === 'outgoing') {
          newMessages.push(convertToMessage(escape(data.payload.message)));
          setMessage(newMessages);
        } else {
          newMessages[newMessages.length - 1].message += escape(data.payload.message);
          setMessage(newMessages);
        }
      } else if (data.type === 'chat') {
        if (messages[messages.length - 1].direction === 'incoming') {
          const newMessages = [...messages];
          newMessages[newMessages.length - 1].message = escape(data.payload.message);
          setMessage(newMessages);
        } else {
          setMessage([...messages, convertToMessage(escape(data.payload.message))]);
        }

        setIsChatbotTyping(false);
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    console.log(WSConnectionStatus[readyState]);
  }, [readyState]);
  // }, [lastMessage, setMessageHistory]);

  async function handleSendMessage(message: string) {
    // setIsChatbotTyping(true);
    const newMessages: MessageModel[] = [
      ...messages,
      {
        message: message,
        direction: 'outgoing',
        sender: 'user',
        position: 'normal'
      }
    ];
    let ws: any;
    if (_.isNil(socket) || socket.readyState !== ReadyState.OPEN) {
      ws = await connectToWebSocket().catch(function(err) {
        alert("Error establishing connection")
        console.error(err);
        return;
      })
    } else {
      ws = socket;
    }
    
    setMessage(newMessages);
    ws.send(JSON.stringify({ type: 'chat', payload: { conversationId, message } }));
    setIsChatbotTyping(true);
    // chatWithBot(conversationId, newMessages).then(function (prediction) {
    //   setMessage([...newMessages, convertToMessage(escape(prediction))]);
    //   setIsChatbotTyping(false);
    // });
  }
  function getTypingIndicator() {
    if (isChatbotTyping) {
      return <TypingIndicator content={`Chatbot is typing`} />;
    }
  }

  async function handleSendFeedback(event: any) {
    await sendFeedback(conversationId, sentiment, feedbackComment);
    setFeedbackComment('');
    setSentiment('like');
    setShowFeedbackModal(false);
  }

  function handleSentimentChange(event: any) {
    setSentiment(event.target.value);
  }
  function handleFeedbackCommentChange(event: any) {
    setFeedbackComment(event.target.value);
  }
  const handleCloseFeedbackModal = () => setShowFeedbackModal(false);
  const handleFeedbackClick = () => setShowFeedbackModal(true);

  return (
    <>
      <div className="h-100 w-100 d-flex flex-column overflow-hidden chat-window">
        <div className="chat-header">
          <img src={require('./assets/sf_logo_black.png')} id="sf-logo" />
          <Button onClick={handleFeedbackClick} variant="contained" id="feedback-button">
            Give Your Feedback
          </Button>
        </div>
        <Modal show={showFeedbackModal} onHide={handleCloseFeedbackModal}>
          <Modal.Header closeButton>Give feedback on this conversation</Modal.Header>
          <Modal.Body>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={sentiment}
              name="radio-buttons-group"
              onChange={handleSentimentChange}
            >
              <FormControlLabel value="like" control={<Radio />} label="&#128077;" />
              <FormControlLabel value="dislike" control={<Radio />} label="&#128078;" />
              <p>Comments (optional)</p>
              <TextareaAutosize minRows={5} onChange={handleFeedbackCommentChange} value={feedbackComment} />
            </RadioGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCloseFeedbackModal}>Close</Button>
            <Button onClick={(e) => handleSendFeedback(e)}>Send</Button>
          </Modal.Footer>
        </Modal>
        <Container fluid className="p-4 flex-grow-1 position-relative overflow-hidden">
          <MainContainer responsive>
            <ChatContainer>
              <MessageList typingIndicator={getTypingIndicator()}>
                {messages.map((m: MessageModel) => (
                  <Message key={Math.random()} model={m} />
                ))}
              </MessageList>
              <MessageInput
                placeholder="Type message here"
                fancyScroll={true}
                onSend={function (message) {
                  handleSendMessage(message);
                }}
                disabled={isChatbotTyping}
                attachButton={false}
              />
            </ChatContainer>
          </MainContainer>
        </Container>
        <div className="chat-footer">
          <p id="copyright">Confidential – SuccessFinder, Inc. © All Rights Reserved. SuccessFinder, Inc. 2024.</p>
          <p id="version">v0.1.1</p>
        </div>
      </div>
    </>
  );
}
