import { MessageModel } from '@chatscope/chat-ui-kit-react';
import axios from 'axios';
import { convertToChatbotMessages } from '../utils';
import { HTTP_API_URL } from '../config';

export async function login(token: string) {
  return axios
    .post(
      HTTP_API_URL + '/api/login',
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true
      }
    )
    .catch(function (error) {
      if (error.response && error.response.status === 401) {
        // Handle the 401 Unauthorized error here
        console.log('Unauthorized: Please log in or provide valid credentials.');
        throw new Error('AUTH_FAILED');
      } else {
        console.error(error);
        console.log('Error while communicating with API');
        throw new Error('API_COMMUNICATION_ERROR');
      }
    });
}

export async function chatWithBot(conversationId: string, messages: MessageModel[]) {
  try {
    const token = localStorage.getItem('sfChatbotToken');
    const response = await axios.post(
      HTTP_API_URL + '/api/chatbot/chat',
      {
        conversationId,
        messages: convertToChatbotMessages(messages)
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response.data.prediction;
  } catch (error) {
    return 'Error while communicating with API: ' + error;
  }
}
export async function chatWithBotWS(conversationId: string, messages: MessageModel[]) {
  try {
    const token = localStorage.getItem('sfChatbotToken');
    const response = await axios.post(
      HTTP_API_URL + '/api/chatbot/chat',
      {
        conversationId,
        messages: convertToChatbotMessages(messages)
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response.data.prediction;
  } catch (error) {
    return 'Error while communicating with API: ' + error;
  }
}
export async function sendFeedback(conversationId: string, sentiment: string, comment: string) {
  try {
    const token = localStorage.getItem('sfChatbotToken');
    await axios.post(
      HTTP_API_URL + 'chatbot/feedback',
      {
        conversationId,
        sentiment,
        comment
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
  } catch (error) {
    console.error('Error communicating with API: ' + error);
  }
}
