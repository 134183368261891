import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Chat from './Chat';
import Home from './Home';
import './styles.css';

function RequireAuth({ children, redirectTo }: { children: any; redirectTo: any }) {
  const token = localStorage.getItem('sfChatbotToken');
  return token ? children : <Navigate to={redirectTo} />;
}

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/chat"
          element={
            <RequireAuth redirectTo="/">
              <Chat />
            </RequireAuth>
          }
        />
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
